<template>
  <div v-if="showPreviewType === 0">
    <div v-if="showPreview">
      <div v-if="ratioType === 1" class="border-box-preview1-1">
        <b-img
          class="preview-box-image"
          :src="showPreview"
          @error="handleImageSrc"
        />

        <font-awesome-icon
          icon="times-circle"
          class="text-white position-absolute delete-icon1-1 pointer"
          v-if="showPreview && !disabled"
          @click="deleteImage()"
          :disabled="disabled"
        />
      </div>
      <div v-else-if="ratioType === 16" class="border-box-preview16-9">
        <b-img
          class="preview-box-image"
          :src="showPreview"
          @error="handleImageSrc"
        />
        <font-awesome-icon
          icon="times-circle"
          class="text-white position-absolute delete-icon1-1 pointer"
          v-if="showPreview && !disabled"
          @click="deleteImage()"
          :disabled="disabled"
        />
      </div>
      <div v-else-if="ratioType === 4" class="border-box-preview4-3">
        <b-img
          class="preview-box-image"
          :src="showPreview"
          @error="handleImageSrc"
        />
        <font-awesome-icon
          icon="times-circle"
          class="text-white position-absolute delete-icon1-1 pointer"
          v-if="showPreview && !disabled"
          @click="deleteImage()"
          :disabled="disabled"
        />
      </div>
      <div v-else class="border-box-preview">
        <b-img
          class="preview-box-image"
          :src="showPreview"
          @error="handleImageSrc"
        />
        <font-awesome-icon
          icon="times-circle"
          class="text-white position-absolute delete-icon2-1 pointer"
          v-if="showPreview && !disabled"
          @click="deleteImage()"
          :disabled="disabled"
        />
      </div>
    </div>
    <div v-else>
      <div v-if="ratioType === 1" class="border-box-none-preview1-1">
        <img
          :src="loading"
          class="loading"
          alt="loading"
          v-if="isLoadingImage"
        />
        <div class="preview-box-none-image1-1" />
      </div>
      <div v-else-if="ratioType === 16" class="border-box-none-preview16-9">
        <img
          :src="loading"
          class="loading"
          alt="loading"
          v-if="isLoadingImage"
        />
        <div class="preview-box-none-image16-9" />
      </div>
      <div v-else-if="ratioType === 4" class="border-box-none-preview4-3">
        <img
          :src="loading"
          class="loading"
          alt="loading"
          v-if="isLoadingImage"
        />
        <div class="preview-box-none-image4-3" />
      </div>
      <div v-else-if="ratioType === 2" class="border-box-none-preview2-1">
        <img
          :src="loading"
          class="loading"
          alt="loading"
          v-if="isLoadingImage"
        />
        <div class="preview-box-none-image2-1" />
      </div>
      <div v-else class="border-box-none-preview">
        <img
          :src="loading"
          class="loading"
          alt="loading"
          v-if="isLoadingImage"
        />
        <div class="preview-box-none-image2-1" />
      </div>
    </div>
  </div>
  <div v-else>
    <div
      v-if="showPreview"
      class="position-relative p-0 embed-responsive embed-responsive-16by9"
    >
      <video ref="videoRef" controls>
        <source :src="showPreview" type="video/mp4" />
      </video>
      <div>
        <font-awesome-icon
          icon="times-circle"
          class="position-absolute delete-icon-video pointer"
          v-if="showPreview && !disabled"
          @click="deleteImage()"
          :disabled="disabled"
        />
      </div>
    </div>
    <div v-else class="border-box-none-preview2-1">
      <div class="preview-box-none-image2-1" />
    </div>
  </div>
</template>

<script>
const loadingImg = require("@/assets/icons/loading.svg");
export default {
  props: {
    disabled: {
      required: false,
      type: Boolean,
    },
    showPreviewType: {
      required: false,
      type: Number,
    },
    showPreview: {
      required: true,
      type: String | null,
    },
    isLoadingImage: {
      required: false,
      type: Boolean,
    },
    ratioType: {
      required: true,
      type: Number,
    },
  },
  data() {
    return {
      loading: loadingImg,
    };
  },
  methods: {
    deleteImage() {
      this.$emit("delete");
    },
  },
};
</script>

<style scoped>
.delete-icon1-1 {
  color: #9b9b9b !important;
  margin-left: -14px;
}
.delete-icon2-1 {
  color: #9b9b9b !important;
  margin-left: -14px;
}
.delete-icon-video {
  color: #9b9b9b !important;
  top: 0px;
  right: 0px;
}
.border-box-preview1-1 {
  width: 75%;
  max-width: 350px;
  max-height: 350px;
  aspect-ratio: 1;
  border: 2px dashed #bfbfbf;
  position: relative;
}
.border-box-preview16-9 {
  width: 100%;
  max-width: 500px;
  max-height: 350px;
  aspect-ratio: 16/9;
  border: 2px dashed #bfbfbf;
}
.border-box-preview4-3 {
  width: 100%;
  max-width: 350px;
  max-height: 350px;
  aspect-ratio: 4/3;
  border: 2px dashed #bfbfbf;
}
.border-box-preview2-1 {
  width: 100%;
  border: 2px dashed #bfbfbf;
  max-width: 350px;
  max-height: 350px;
  aspect-ratio: 2;
}
.border-box-preview {
  width: 100%;
  border: 2px dashed #bfbfbf;
}
.preview-box-image {
  width: 100%;
  height: 100%;
  position: relative;
  object-fit: contain;
  object-position: center;
  max-width: 500px;
  max-height: 350px;
}
.border-box-none-preview1-1 {
  position: relative;
  width: 75%;
  border: 2px dashed #bfbfbf;
  max-width: 350px;
  max-height: 350px;
}
.border-box-none-preview {
  position: relative;
  width: 75%;
  border: 2px dashed #bfbfbf;
}
.border-box-none-preview16-9 {
  position: relative;
  width: 100%;
  border: 2px dashed #bfbfbf;
  max-width: 350px;
  max-height: 350px;
}
.border-box-none-preview4-3 {
  position: relative;
  width: 100%;
  border: 2px dashed #bfbfbf;
  max-width: 350px;
  max-height: 350px;
}
.border-box-none-preview2-1 {
  width: 100%;
  position: relative;
  border: 2px dashed #bfbfbf;
  max-width: 350px;
  max-height: 350px;
}
.preview-box-none-image1-1 {
  width: 100%;
  padding-bottom: 100%;
  position: relative;
  max-width: 350px;
  max-height: 350px;
}
.preview-box-none-image2-1 {
  width: 100%;
  padding-bottom: 50%;
  position: relative;
  max-width: 350px;
  max-height: 350px;
}
.preview-box-none-image16-9 {
  width: 100%;
  /* padding-bottom: 100%; */
  aspect-ratio: 16/9;
  position: relative;
  max-width: 350px;
  max-height: 350px;
}
.preview-box-none-image4-3 {
  width: 100%;
  /* padding-bottom: 100%; */
  aspect-ratio: 4/3;
  position: relative;
  max-width: 350px;
  max-height: 350px;
}
</style>
